export const enhancer = el => {
  const logo = document.querySelector('.site-header__logo');
  const menu = document.getElementById('menu');
  const menuInner = document.querySelector('.site-header__inner');
  const close = document.createElement("span");
  close.classList.add('site-header__nav-primary-close');
  close.classList.add('site-header__button-icon');
  close.innerHTML = '&#735;';

  el.addEventListener('click', (e) => {
    menu.prepend(logo);
    menu.prepend(close);
    if (el.hash === '#menu') {
      document.body.classList.add('js-disable-scrollbar');
    }
  });

  close.addEventListener('click', (e) => {
    menuInner.prepend(logo);
    menu.querySelector('.site-header__nav-primary-close').remove();
    window.location.hash = '';
    document.body.classList.remove('js-disable-scrollbar');
  });
};
