import { getScrollPosition } from './util';

export const menuClass = el => {
  const header = document.querySelector('.flexible-content > section:first-child');
  if (getScrollPosition().y < header.offsetHeight) {
    el.classList.add('site-header--invert');
  } else {
    el.classList.remove('site-header--invert');
  }
};

export const enhancer = el => {
  menuClass(el);
  window.addEventListener("scroll", (event) => {
    menuClass(el);
  });
};
