/* eslint-disable */
import { forEach } from './util';

export const showActive = (el) => {
  const items = el.querySelectorAll('.slider-block__list-item');
  forEach(items, (item) => {
    if (item.classList.contains('slider-block__list-item--active')) {
    } else {
      item.classList.add('slider-block__list-item--hidden');
    }
  });
};

export const showNext = () => {
  const activeNextItem = document.querySelector('.slider-block__list-item--active');
  if (activeNextItem.nextElementSibling === null) {
    const firstItem = document.querySelector('.slider-block__list-item');
    activeNextItem.classList.replace('slider-block__list-item--active', 'slider-block__list-item--hidden');
    firstItem.classList.replace('slider-block__list-item--hidden', 'slider-block__list-item--active');
  } else {
    activeNextItem.classList.replace('slider-block__list-item--active', 'slider-block__list-item--hidden');
    activeNextItem.nextElementSibling.classList.replace('slider-block__list-item--hidden', 'slider-block__list-item--active');
  }
};

export const showPrev = () => {
  const activePrevItem = document.querySelector('.slider-block__list-item--active');
  if (activePrevItem.previousElementSibling === null) {
    const prevItems = document.querySelectorAll('.slider-block__list-item');
    const lastPrevItem = prevItems[prevItems.length- 1];
    activePrevItem.classList.replace('slider-block__list-item--active', 'slider-block__list-item--hidden');
    lastPrevItem.classList.replace('slider-block__list-item--hidden', 'slider-block__list-item--active');
  } else {
    activePrevItem.classList.replace('slider-block__list-item--active', 'slider-block__list-item--hidden');
    activePrevItem.previousElementSibling.classList.replace('slider-block__list-item--hidden', 'slider-block__list-item--active');
  }
};

export const prevButton = () => {
  const prevButton = document.createElement('span');
  prevButton.classList.add('button', 'button--primary', 'slider-block__list-item-button');
  prevButton.innerHTML = `<svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg"><g stroke="#000" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M27 9H3" stroke-linecap="square"/><path d="M10 1L2 9l8 7.415"/></g></svg>`;
  prevButton.addEventListener('click', (e) => {
    showPrev();
  });
  return prevButton;
};


export const nextButton = () => {
  const nextButton = document.createElement('span');
  nextButton.classList.add('button', 'button--primary', 'slider-block__list-item-button');
  nextButton.innerHTML = `<svg width="28" height="18" viewBox="0 0 28 18" xmlns="http://www.w3.org/2000/svg"><title>Slice 1</title><g stroke="#000" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M1 9h24" stroke-linecap="square"/><path d="M18 1l8 8-8 7.415"/></g></svg>`;
  nextButton.addEventListener('click', (e) => {
    showNext();
  });
  return nextButton;
};

export const controllers = (el) => {
  const items = el.querySelectorAll('.slider-block__list-item-body');
  forEach(items, (item) => {
    item.appendChild(prevButton());
    item.appendChild(nextButton());
  });
};


export const enhancer = (el) => {
  controllers(el);
  showActive(el);
};
